import BaseRenderMixin from "@/components/base/BaseRenderMixin";

export default {
    name: "BaseFilterCategItemComponent",
    mixins: [BaseRenderMixin],
    props: {
        category: String
    },
    methods: {
        make_element_tree(createElement, element_data, options = {},) {
            let data = {}
            let el, props, attrs, style, children

            [el, props] = this.get_el_props(element_data)
            attrs = this.get_attrs(element_data)
            children = this.get_children(element_data, createElement)
            style = this.get_style(element_data)

            if (props) data.props = props
            if (attrs) {
                data.attrs = attrs
                if (attrs['deepsel-filter-categ-item']) data.on = {click: this.on_click}
            }
            if (style) data.style = style

            for (let key in options) data[key] = options[key]

            return createElement(
                el,
                data,
                children
            )
        },
        get_children(element_data, createElement) {
            let children = []
            if (element_data.child) {
                for (let item of element_data.child) {
                    if (item.node === "element") {
                        children.push(this.make_element_tree(createElement, item));
                    } else if (item.node === "text") {
                        children.push(this.$props.category)
                    }
                }
            }
            return children
        },
        on_click() {
            this.$store.state.filter.category = this.$props.category
        },
    }
}
